.page-admin .nav-main a.active, .nav-main a:focus, .nav-main a:hover{
    background: #1a90ff14;
    color: #1a90ff;
}
.ant-form-item-label {
    text-align: left;
    width: 100%;
}

.ant-notification{
    /* z-index: 1050; */
}
.page-admin .nav-main a>i{
    top: 50%;
}
.page-admin .nav-main a{
    padding-left: 42px;
}
#page-container.page-header-fixed #page-header, #page-container.page-header-glass #page-header, #sidebar{
    z-index: 999!important;
}
.page-admin #sidebar{
    overflow-y: auto;
}