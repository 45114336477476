body,
html {
    background-color: #eff2f5;
}

a {
    /* outline: none; */
    text-decoration: none;
}

#page-header {
    min-height: 50px;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    color: #1c1e21;
    z-index: 99;
    box-shadow: 0 3px 6px rgba(192, 204, 219, .6);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(192, 204, 219, .6);
    border-radius: 10px;
}

::-webkit-scrollbar-corner {
    background-color: #fff;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

.block {
    margin-bottom: 24px;
    background-color: #fff;
    box-shadow: 0 1px 1px #e4e7ed;
}

.block.block-rounded {
    border-radius: 4px;
}

.block-content {
    transition: opacity .2s ease-out;
    margin: 0 auto;
    padding: 20px 20px 1px;
    width: 100%;
    overflow-x: visible;
}

#main-container {
    margin-top: 20px;
}

#page-header .logo {
    height: 33px;
    max-height: 60px;
}

/* style post */
.post-list .post-item {
    background: #fff;
    border-radius: 5px 5px;
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.post-list .post-header {
    padding: 0.75em;
}

.post-list .post-header h3 {
    display: inline-block;
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 15px;
    margin-bottom: 0px;
}

.post-list .post-info {
    position: relative;
    display: inline-block;
    width: 100%;
}

.post-list .post-info .post-card-body {
    font-size: 12px;
    font-weight: 500;
    color: #555;
}

.post-list .post-info .page-link {
    border-top: 0;
    border-right: 0;
    border-left: 0;
}

.post-list .post-info .page-link span,
.post-list .post-info .page-link a {
    line-height: 19px;
}

.post-list .post-info .post-count>div {
    margin-right: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #555;
}

.post-list .post-info .post-count>div i {
    margin-right: 0px;
}

.post-list .post-info .post-increase {
    font-size: 11px;
}

.post-list .post-tool a {
    color: #555;
    font-size: 13px;
    margin-right: 6px;
}

.post-list .post-tool>a:last-child {
    margin-right: 0px;
}

.post-list .post-info .post-time {
    color: #555;
}

.card-img-top.card-img-design {
    height: 18em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #f8f8f8;
    text-indent: -99999px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-radius: 5px 5px 0 0;
    margin: 0px;
    padding: 0.75em;
    width: auto;
    cursor: pointer;
    background-image: url('../image/no-photo.png');
    margin-bottom: 0px;
    max-height: 22em;
    /* transition: background-position ease 0.55s; */
}

.card-img-top.card-img-design:hover:before {
    transition: background ease-in-out 0.15s;
    /* background: rgba(0, 0, 0, 0.75); */
    background: rgb(2, 0, 36);
    background: linear-gradient(360deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 0.6530987394957983) 0%, rgba(0, 0, 0, 0.022846638655462215) 50%);
}

.card-img-top .card-img-design:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: -1;
}

.card-img-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
}

.card-img-top video {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
}

.views {
    left: 0px;
    font-size: 11px;
    line-height: 18px;
    bottom: auto;
    top: 0;
    border-radius: 0px 0px 5px 0px;
    display: block;
    background: #e83f8b;
    text-indent: 0;
    width: auto;
    position: absolute;
    z-index: 2;
    padding: 0 5px;
    font-weight: 300;
}

.ads {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
    background: #fff;
    font-size: 1.45rem;
    color: #63d8c8;
    padding: 0 3px;
    font-weight: bold;
    border: #fff solid 0px;
    text-indent: 0;
    line-height: 1;
    border-radius: 0 0 3px 0;
}

.platform_icon {
    left: auto;
    right: 0;
    font-size: 11px;
    padding: 3px 5px 3px 5px;
    color: #555;
    text-transform: capitalize;
    border-radius: 0 0px 0 6px;
    font-weight: 500;
    background: #fff;
}

.platform_icon {
    padding: 3px 10px 3px 5px;
    color: #fff;
    border-radius: 0 0px 0 6px;
    background: #e83f8b;
}

.post-list .post-author {
    padding: 10px 0.75rem 0px 0.75rem;
    border-radius: 5px;
    background-color: #fff;
    min-height: 10px;
}

.author_name {
    color: #63d8c8;
    font-size: 13px;
    font-weight: 700;
    display: block;
    letter-spacing: 0;
    line-height: 1;
    margin: 0px 0 0px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 3px 10px 0 0;
}

.author_name a {
    color: #63d8c8;
    text-decoration: none;
}

.post-list .post-author .post-view-store {
    /* padding-right: 10px; */
}

.post-list .post-author .post-view-store>div {
    font-size: 13px;
    color: #424242 !important;
    width: 50px;
}

.post-list .post-author .post-view-store .text-info {
    display: inline-block;
    margin: 0 0px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
    padding: 0px 1px;
    text-align: right;
    border-radius: 2px;
    font-size: 13px;
    color: #424242 !important;
    flex: 1;
    text-align: left;
}

.post-list .post-author .post-view-store .text-info a {
    text-decoration: none;
    margin-right: 5px;
    font-size: 13px;
}

.post-list .post-author .post-view-store .text-info a:last-child {
    margin-right: 0px;
}

.post-list .post-author .post-view-store .text-info b {
    display: inline-block;
    text-align: left;
}

.post-list .post-author .post-view-store.post-view-ga .text-info {
    display: flex;
    flex-wrap: wrap;
}

.btn-bg-default {
    background-color: #0dbdbd;
    border-color: #0dbdbd;
}

#btn-top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    z-index: 98;
    border: none;
    font-size: 20px;
    outline: none;
    cursor: pointer;
    padding: 6px 12px;
    background: #0dbdbd;
    color: #fff;
    display: none;
}

.btn-loading {
    pointer-events: none;
    height: 45px;
    border: none
}

.preview-post img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preview-post video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lazyload-wrapper {
    width: 100%;
}

.preview-post .post-count {
    padding: 10px 15px;
}

.preview-post .post-count>div {
    font-size: 13px;
    font-weight: 500;
    color: #555;
}

.preview-post .post-count>div i {
    margin-right: 0px;
}

.preview-post .post-count .post-increase {
    font-size: 11px;
}

.preview-post .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.preview-post a:focus {
    box-shadow: unset;
}

.block-filter {
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.ant-modal-content {
    border-radius: 5px;
}

.ant-modal-header {
    border-radius: 5px 5px 0 0;
}

.preview-post .post-title {
    color: #606266;
    padding: 0px;
    margin: 10px 0px 5px 0px;
    word-break: break-word;
    width: calc(100% - 25px);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
}

.preview-post .post-full-content {
    max-height: 4.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 13px;
    color: #333;
    padding-bottom: 15px;
}

.preview-post .btn-slider {
    color: #fff;
    position: absolute;
    top: 50%;
    font-weight: 600;
    font-size: 56px;
    transform: translateY(-50%);
}

.preview-post .btn-slider.btn-next {
    right: -40px;
}

.preview-post .btn-slider.btn-prev {
    left: -40px;
}

.preview-post .ant-modal-footer p {
    margin: 0;
    font-size: 13px;
    text-align: center;
    width: 100%;
    color: #bbb;
    -webkit-transition: color ease .2s;
    transition: color ease .2s;
}

.preview-post .ant-modal-footer p span {
    display: inline-block;
    margin: 0 20px 5px;
}

.preview-post .block-page-info span {
    line-height: 20px;
}

.preview-post .block-content-info .block-content-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #f8f8f8;
    cursor: pointer;
    background-image: url('../image/no-photo.png');
}

/** style login**/
#page-login {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #fff;
}

#page-login .info-holder {
    color: #fff;
}

#page-login .info-holder h3 {
    display: inline-block;
    color: #fff;
    text-align: left;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 30px;
    width: 100%;
    max-width: 378px;
    padding-right: 30px;
}

#page-login .info-holder p {
    font-weight: 350;
    font-size: 15px;
    line-height: 27px;
}

/** form filter ***/
.form-filter .label-form-filter {
    width: 90px;
    flex: 0 0 90px;
}

.loading-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #0dbdbd;
    z-index: 99;
}

.loading-screen:after {
    content: "";
    width: 60px;
    height: 60px;
    position: absolute;
    top: -30px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border: 6px solid #f2f2f2;
    border-top: 6px dotted #f2f2f2;
    border-bottom: 6px dotted #f2f2f2;
    border-radius: 50%;
    animation: loading 2s infinite;
}

.loading-screen:before {
    font-family: 'Lobster', cursive;
    font-size: 20px;
    letter-spacing: 1px;
    color: white;
    content: "Loading...";
    position: absolute;
    top: 57%;
    text-align: center;
    right: 0;
    left: 0;
    margin: auto;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(360deg);
    }
}


#AliRanjbar {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 13px;
    color: #444;
    font-family: 'Acme', sans-serif;
    z-index: 99;
}


@media (max-width: 767px) {
    .preview-post {
        max-width: initial !important;
        overflow-x: hidden !important;
        width: 100% !important;

    }

    .preview-post .block-content-info {
        padding: 0px;
    }

    .preview-post .ant-modal-footer {
        display: none;
    }

    .preview-post .btn-slider {
        font-size: 30px;
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.3);
        padding: 0px 5px;
    }

    .preview-post .btn-slider.btn-next {
        right: 10px;
    }

    .preview-post .btn-slider.btn-prev {
        left: 10px;
    }
}

@media (min-width: 1600px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1480px;
    }
}